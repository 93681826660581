import { handleActions, createAction } from 'redux-actions';

// action types
const SHOW_SEARCH_MODAL = 'search/SHOW_SEARCH_MODAL';
const HIDE_SEARCH_MODAL = 'search/HIDE_SEARCH_MODAL';

// action creators
export const showSearchModal = createAction(SHOW_SEARCH_MODAL);
export const hideSearchModal = createAction(HIDE_SEARCH_MODAL);

// initial state
const initialState = {
  visibleSearchModal: false,
};

// reducer
export default handleActions(
  {
    [SHOW_SEARCH_MODAL]: state => ({
      ...state,
      visibleSearchModal: true,
    }),
    [HIDE_SEARCH_MODAL]: state => ({
      ...state,
      visibleSearchModal: false,
    }),
  },
  initialState
);
