import { handleActions, createAction } from 'redux-actions';

// action types
const RESIZE = 'base/RESIZE';
const HIDE_MOBILE_NAV = 'base/HIDE_MOBILE_NAV';
const TOGGLE_MOBILE_NAV = 'base/TOGGLE_MOBILE_NAV';

// action creators
export const resize = createAction(RESIZE);
export const hideMobileNav = createAction(HIDE_MOBILE_NAV);
export const toggleMobileNav = createAction(TOGGLE_MOBILE_NAV);

// initial state
const initialState = {
  breakpoint: '',
  isMobileMode: false,
  visibleMobileNav: false,
};

// reducer
export default handleActions(
  {
    [RESIZE]: (state, action) => {
      const { breakpoint, isMobileMode } = action.payload;
      return {
        ...state,
        breakpoint,
        isMobileMode,
      };
    },
    [HIDE_MOBILE_NAV]: state => ({
      ...state,
      visibleMobileNav: false,
    }),
    [TOGGLE_MOBILE_NAV]: state => ({
      ...state,
      visibleMobileNav: !state.visibleMobileNav,
    }),
  },
  initialState
);
